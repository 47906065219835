import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BasicTemplate } from '@templates/BasicTemplate';
import PrivacyHero from '@components/molecules/PrivacyHero/PrivacyHero';
import styled from 'styled-components';
import { data } from 'autoprefixer';
import { theme } from '@theme/theme';

const PageContainer = styled.div`
  width: 90%;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;

  @media (min-width: 1025px) {
    max-width: 1250px;
  }

  article > * {
    font-family: ${({ theme }) => theme.primaryFont};
  }
`;

const PrivacyPolicy = () => {
  const data = useStaticQuery(query);
  return (
    <BasicTemplate header={PrivacyHero} title="Rząska">
      <PageContainer>
        <article
          dangerouslySetInnerHTML={{
            __html: data.datoCmsPrivacyPolicy.content,
          }}
        />
      </PageContainer>
    </BasicTemplate>
  );
};

export const query = graphql`
  query PrivacyQuery {
    datoCmsPrivacyPolicy {
      content
    }
  }
`;

export default PrivacyPolicy;
